.carciofo {
    padding-left: 0;
    padding-right: 0;
}

.content-section {
    margin: 2%;
    background-color: #ffc107;
    border-radius: 20px;
    padding: 1%;
    box-shadow: 0 8px 6px -6px rgb(193, 188, 188);
}

.photoHolder6 {
    background-image: url('../../assets/coltivazione-delle-angurie-1024x682.jpg') !important;
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    width: 100vw; 
    min-height: 700px;
    box-shadow: 0 8px 6px -6px rgb(193, 188, 188);
    margin-bottom: 100px;
}

.photoHolder6 h1 {
    padding-top: 300px;
    color: #F2E9C0;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3em;
}

.section-title {
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
}

.section-content {
    color: white;
    font-size: 1.2em;
    margin: 5%;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.text-content, .image-content {
    padding: 20px;
}

.image-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.image-circle {
    background-size: cover;
    width: 400px;
    height: 400px;
    border-radius: 50%;
    margin: 10px 0;
    background-position: center;
}
.coltura-table {
    margin-top: 50px;
    background-color: #5d8030 !important;
}
.table-responsive {
    margin-top: 50px;
    overflow-x: auto;
  }
.h2iamo {
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
}  
th {
    background-color: #5d8030 !important;
}
td {
    background-color: #5d8030 !important;
    color: white !important;
}
@media (max-width: 768px) {
    .text-content, .image-content {
        width: 100%;
        order: 0; 
    }
    .article-row:nth-child(odd) .text-content {
        order: 1;
    }
    .article-row:nth-child(odd) .image-content {
        order: 2;
    }
    .article-row:nth-child(even) .text-content {
        order: 3;
    }
    .article-row:nth-child(even) .image-content {
        order: 4;
    }
    .image-content {
        padding-top: 20px;
    }
    .image-circle {
        width: 290px;
        height: 290px;
    }
}

.animated-left, .animated-right {
    opacity: 0;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.animated-left {
    animation: slideInFromLeft 0.8s ease-out 1s forwards;
}

.animated-right {
    animation: slideInFromRight 0.8s ease-out 1s forwards;
}
