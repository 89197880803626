.ciao {
    margin-top: 160px !important;
}

.contact-details h3 {
    font-weight: bold;
}

.contact-details p {
    margin-bottom: 0.5rem;
}

@media (max-width: 767px) {
    .contact-details {
        margin-bottom: 2rem;
    }
}
.custom1button {
    width: 150px;
    background-color: #76a03f !important; 
    color: white !important; 
    font-weight: bold !important; 
    padding: 10px 20px !important; 
    border: none !important; 
    border-radius: 10px 0 10px 0 !important; 
    box-shadow: 0 8px 6px -6px grey !important;
    transition: background-color 0.3s ease !important;
    margin-top: 20px; 
}
.custom1button:hover {
    background-color: #5d8030 !important;
    color: #fff !important; 
  }

.customforms {
    max-width: 500px;
}

.contatti-container {
    padding-top: 60px;
    padding-bottom: 60px;
    
  }
  
  .contact-details {
    font-size: 1rem;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .contact-details h3 {
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
  }
  
  .email-form-container {
    padding: 2rem;
    background: #ffc107;
    border-radius: 8px;
    color: white;
  }
  
  .contact-form {
    max-width: 500px;
    margin: auto;
  }
  
  @media (min-width: 992px) {
    .contatti-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .contact-details {
      margin-right: 3rem;
    }
  
    .email-form-container {
      padding: 2rem 4rem;
    }
  
    .text-center.d-lg-none {
      display: none;
    }
  }
  .cont2 {
    margin-top: 200px;
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
  }
  .cont3 {
    margin-top: 20px;
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
  }
  .cont4 {
    margin-top: 160px;
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.5em;
  }
  .backgroundiamo {
    
    background-color: #ffc107;
    border-radius: 20px;
    box-shadow: 0 8px 6px -6px rgb(193, 188, 188);
  }
.quattro {
  color: white !important;
}
  