.galleria-container {
    margin-top: 160px;
    padding: 20px;
    background-color: #ffc107;
    border-radius: 20px;
    box-shadow: 0 8px 6px -6px rgb(193, 188, 188);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1200px; /* Adjust based on your design */
    margin: 20px auto;
}

.gallery-image {
    width: 100%;
    height: 150px; /* Fixed height for all images */
    object-fit: cover;
    cursor: pointer;
}

.h2iamo {
    color: white;
    font-family: "Titan One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2em;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
}

.modal-carousel .modal-content {
    background: #ffc107;
    color: white;
}

.modal-carousel img {
    height: 80vh;
    object-fit: cover;
}

.cursor-pointer {
    cursor: pointer;
}
